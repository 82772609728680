import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useImageQuery from '../../hooks/useImageQuery';
import './styles.scss';

const Video = ({ src, poster, autoplay, title, className, ...other }) => {
  const [ready, setReady] = useState(false);
  const [hostname, setHostname] = useState('');
  const { getFinalImagePath, getFinalImagePathByFilename } = useImageQuery();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          gaId
        }
      }
    }
  `);

  // This will run once after the initial render.
  useEffect(() => {
    setHostname(
      window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    );
    setReady(true);
  }, []);

  const getQueryString = () => {
    let qs = `videoSrc=${src}`;

    if (poster) {
      if (poster.startsWith('https://') || poster.startsWith('http://')) {
        qs += `&posterSrc=${poster}`;
      } else {
        // If we reach here, this must be a local path
        let imagePath = getFinalImagePath(poster);
        if (!imagePath) {
          imagePath = getFinalImagePathByFilename(poster);
        }
        qs += `&posterSrc=${encodeURI(hostname + imagePath)}`;
      }
    }
    if (site.siteMetadata.gaId) {
      qs += `&gaId=${site.siteMetadata.gaId}`;
    }

    return qs;
  };

  const getVideoUrl = (src) => {
    if (src.includes('app.discoveryeducation')) {
      return src;
    } else {
      return `https://embed.discoveryeducation.com?${getQueryString()}`;
    }
  };

  return (
    <>
      {ready && (
        <div className={classNames('video', className)} {...other}>
          <iframe
            src={getVideoUrl(src)}
            title={title}
            width="560"
            height="349"
            frameBorder="0"
            autoPlay={autoplay}
            allowFullScreen
          ></iframe>
        </div>
      )}
    </>
  );
};

Video.defaultProps = {
  src: null,
  poster: null,
  autoplay: false,
  className: null,
};

Video.propTypes = {
  /** The URL of the video src. */
  src: PropTypes.string.isRequired,
  /** The title of the video */
  title: PropTypes.string.isRequired,
  /** The URL for a poster image */
  poster: PropTypes.string,
  /** Toggle autoplay */
  autoplay: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
};

export default Video;
