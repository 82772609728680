import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Action from './Action';
import Button from '../Button';
import Image from '../Image';
import Icon from '../Icon';
import virtualIcon from '../../images/icon-virtual-activity.png';
import OauthContext from '../../context/OauthContext';
import './styles.scss';

const Resource = ({
  tophat,
  img,
  icon,
  subject,
  title,
  audience,
  duration,
  description,
  actions,
  horizontal,
  className,
  alignedButtons,
  locked,
  withBorder,
}) => {
  const oauthContext = useContext(OauthContext);
  const getClassNames = () =>
    classNames(
      'resource',
      horizontal && 'resource--horizontal',
      withBorder && 'resource--bordered',
      className
    );

  const renderImage = () => {
    let image = (
      <>
        <Image filename={img} className="resource__image" />
        {icon && <Image filename={icon} className="resource__icon" />}
      </>
    );

    if (actions && actions[0].linkFromImg) {
      const { metadata, label, ...actionProps } = actions[0];
      image = (
        <Action
          {...actionProps}
          label={image}
          noIcon={!actions[0].module}
          asLink
        />
      );
    }

    return (
      <>
        {tophat && <div className="resource__tophat">{tophat}</div>}
        <div className="resource__image-container">{image}</div>
      </>
    );
  };

  const renderContent = () => (
    <div className="resource__content">
      {subject && <div className="resource__subject">{subject}</div>}
      <h2 className="resource__title">{title}</h2>
      {audience && <div className="resource__audience">{audience}</div>}
      {duration && (
        <div className="resource__duration">
          <Icon name="clock" marginRight /> {duration}
        </div>
      )}
      {description && (
        <div dangerouslySetInnerHTML={{__html: description}} className="resource__description" />
      )}
      {actions && (
        <div
          className={classNames(
            'resource__actions',
            alignedButtons && 'resource__actions--aligned'
          )}
        >
          {locked && !oauthContext.loggedIn ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button to="/app/oauth/login" forceAnchor>
                <Icon name="lock" marginRight />
                Sign In
              </Button>
              {actions != null && actions.some((entry) => entry.virtual) && (
                <img
                  className="resource__action__virtual-icon"
                  src={virtualIcon}
                  alt="Virtual Activity"
                />
              )}
            </div>
          ) : (
            actions.map((action, i) => <Action key={i} {...action} />)
          )}
        </div>
      )}
    </div>
  );

  if (horizontal) {
    return (
      <Container>
        <div className={getClassNames()}>
          <Row>
            <Column size={5}>{renderImage()}</Column>
            <Column size={7}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    );
  }

  return (
    <div className={getClassNames()}>
      {renderImage()}
      {renderContent()}
    </div>
  );
};

Resource.defaultProps = {
  tophat: null,
  subject: null,
  audience: null,
  duration: null,
  description: null,
  actions: null,
  horizontal: false,
  className: null,
  alignedButtons: true,
  withBorder: true,
  locked: false,
};

Resource.propTypes = {
  /** The image's file name */
  img: PropTypes.string.isRequired,
  /** The icon's file name */
  icon: PropTypes.string,
  /** Text for the tophat */
  tophat: PropTypes.string,
  /** The subject */
  subject: PropTypes.string,
  /** The title */
  title: PropTypes.string.isRequired,
  /** The audience */
  audience: PropTypes.string,
  /** The duration */
  duration: PropTypes.string,
  /** The description */
  description: PropTypes.node,
  /** The actions */
  actions: PropTypes.arrayOf(PropTypes.object),
  /** Horizontal orientation */
  horizontal: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
  /** Align buttons in multiple cards horizontally */
  alignedButtons: PropTypes.bool,
  /** Flag which determines if the user needs to be authenticated to access the actions */
  locked: PropTypes.bool,
  /** Surround the card with a border */
  withBorder: PropTypes.bool,
};

export default Resource;
