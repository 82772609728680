import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Image from '../Image';
import './styles.scss';
import classNames from 'classnames';

/** A carousel component that uses the 'react-slick' slider library. */

const Carousel = ({
  headingClassOverride,
  imageContainerClassOverride,
  slideClassOverride,
  settings,
  slides,
}) => {
  const slickSlider = useRef(null);

  const defaultSettings = {
    accessibility: true,
    arrows: false,
    dots: false,
    dotsClass: 'carousel__dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    appendDots: (dots) => (
      <ul onClick={stopAutoplay} onKeyDown={stopAutoplay} role="presentation">
        {dots}
      </ul>
    ),
  };

  const sliderSettings = { ...defaultSettings, ...settings };

  const stopAutoplay = (e) => {
    // Check that a child button was clicked
    if (
      e.currentTarget.contains(e.target) &&
      !e.currentTarget.isSameNode(e.target)
    ) {
      slickSlider.current.slickPause();
    }
  };

  return (
    <div className="carousel">
      <Slider {...sliderSettings} ref={slickSlider}>
        {slides.map((slide, i) => (
          <div
            className={classNames('carousel__slide', slideClassOverride)}
            key={i}
          >
            <div
              className={classNames(
                'carousel__image-container',
                imageContainerClassOverride
              )}
            >
              <Image className="carousel__image" filename={slide.image} />
            </div>
            <h2
              className={classNames('carousel__heading', headingClassOverride)}
            >
              {slide.title}
            </h2>
            <p className="carousel__body">{slide.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  /** Override default settings. See https://react-slick.neostack.com/docs/api/ and http://kenwheeler.github.io/slick/ */
  settings: PropTypes.object,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }).isRequired
  ),
};

export default Carousel;
