import PropTypes from 'prop-types';
import React from 'react';
import virtualIcon from '../../images/icon-virtual-activity.png';
import Button from '../Button';
import Icon from '../Icon';

import './styles.scss';

const Action = ({
  inverse,
  label,
  metadata,
  module,
  moduleName,
  download,
  noIcon,
  asLink,
  virtual,
}) => {
  const getClickType = () => {
    return download ? 'download' : 'Module Launch';
  };

  const renderButton = () => (
    <Button
      to={download || module}
      inverse={virtual || inverse}
      target="_blank"
      forceAnchor={!!(download || module)}
      rel="noopener"
      className="resource__action-link"
      link={asLink}
      data-click-type={getClickType()}
      data-click-name={moduleName}
    >
      {!noIcon && (
        <Icon
          name={download ? 'download' : 'module'}
          className="resource__action-icon"
          marginRight
        />
      )}
      {label}
    </Button>
  );

  return (
    <div className="resource__action">
      {renderButton()}
      {metadata && <span className="resource__metadata">{metadata}</span>}
      {virtual && (
        <img
          className="resource__action__virtual-icon"
          src={virtualIcon}
          alt="Virtual Activity"
        />
      )}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
  inverse: PropTypes.bool,
  virtual: PropTypes.bool,
  moduleName: PropTypes.string,
};

export default Action;
