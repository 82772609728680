import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Container, Row } from '../../components/Grid';
import Hero from '../../components/Hero';
import Layout from '../../components/Layout';
import Quicklinks from '../../components/Quicklinks';
import Quote from '../../components/Quote';
import Resource from '../../components/Resource';
import Section from '../../components/Section';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import Video from '../../components/Video';
import { getKeyedResources } from '../../utils/resource-helper';

const FamiliesActivities = ({ data, pageContext }) => {
  const { t } = useTranslation('families');
  const ENG_LANG = ['us', 'ir', 'uk'];
  const CC_DISCLAIMER = ['ir', 'uk', 'co', 'mx'];

  const textOverlay = (
    <div className="families__text_overlay_container">
      <Container fullWidth={true}>
        <div className="families__text_overlay_inner_container">
          <div className="families__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>
          <span className="families__text_overlay_title color-green text-bold">
            {t('heroTitle1')}{' '}
          </span>
          <br />
          <span className="families__text_overlay_title2">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  const heroImages = [
    { image: 'img-lead-families-1.jpg', text: '', title: '' },
    { image: 'img-lead-families-2.jpg', text: '', title: '' },
    { image: 'img-lead-families-3.jpg', text: '', title: '' },
    { image: 'img-lead-families-4.jpg', text: '', title: '' },
  ];

  const fadeInDelays = [0, 300, 600];

  const {
    dancingToTheBeat,
    livingBestLife,
    foodExperiments,
    exerciseForHealth,
    exerciseForHealthCompanionFamilyGuide,
    nutritiousEating,
    nutritiousEatingCompanionFamilyGuide,
    healthyLivingFactSheets,
    testYourSkills,
    familyConversationStarters,
  } = getKeyedResources(data);

  return (
    <Layout title="Families" className={pageContext.lang}>
      <Hero textOverlay={textOverlay} mediaSource={heroImages} />
      <Section>
        {/* INTRO AND QUICK LINKS */}
        <Container>
          <h2 className="pb-1">{t('activitiesIntroHeading').toUpperCase()}</h2>
          <Row>
            <Column size={9}>
              <p>{t('activitiesIntroBody')}</p>
            </Column>
          </Row>
          <Quicklinks
            className="text-sans-serif mt-2"
            title={t('activitiesIntroQuickLinksLabel').toUpperCase()}
            smoothScroll={true}
            links={[
              {
                target: '#music-video',
                label: t('activitiesIntroQuickLinksLink1').toUpperCase(),
              },
              {
                target: '#self-paced-modules',
                label: t('activitiesIntroQuickLinksLink2').toUpperCase(),
              },
              {
                target: '#activities-and-information-sheets',
                label: t('activitiesIntroQuickLinksLink3').toUpperCase(),
              },
            ]}
          />
        </Container>
      </Section>
      <Section id="music-video" className="pt-0">
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container>
            <h2>{t('activitiesMusicVideoHeading').toUpperCase()}</h2>
            {ENG_LANG.includes(pageContext.lang) ? (
              <Video
                poster="video-still-families.jpg"
                src="https://app.discoveryeducation.com/learn/embedded-player/cf4c375f-1e0b-42a2-bfdd-88c1a3b5e985?utm_source=microsite&utm_medium=embed"
                title={t('activitiesMusicVideoHeading')}
              />
            ) : (
              <Video
                poster="video-still-families.jpg"
                src="https://app.discoveryeducation.com/learn/embedded-player/cf4c375f-1e0b-42a2-bfdd-88c1a3b5e985?utm_source=microsite&utm_medium=embed"
                title={t('activitiesMusicVideoHeading')}
              />
            )}
            <Row className="pt-0.5">
              <Column size={12}>
                {CC_DISCLAIMER.includes(pageContext.lang) && (
                  <h4 style={{ textAlign: 'center', fontSize: '1rem' }}>
                    {t('ccDisclaimer')}
                  </h4>
                )}
              </Column>
            </Row>
            <Row className="pt-1">
              <Column size={9}>
                <p>{t('activitiesMusicVideoBody')}</p>
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
      <Section id="activities-and-information-sheets">
        {/* ACTIVITIES AND INFORMATION SHEETS */}
        <Container>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={dancingToTheBeat.tophat}
                  title={dancingToTheBeat.title}
                  duration={dancingToTheBeat.duration}
                  description={dancingToTheBeat.description}
                  img={dancingToTheBeat.img}
                  actions={dancingToTheBeat.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={livingBestLife.tophat}
                  title={livingBestLife.title}
                  duration={livingBestLife.duration}
                  description={livingBestLife.description}
                  img={livingBestLife.img}
                  actions={livingBestLife.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={foodExperiments.tophat}
                  title={foodExperiments.title}
                  duration={foodExperiments.duration}
                  description={foodExperiments.description}
                  img={foodExperiments.img}
                  actions={foodExperiments.actions}
                />
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="bg-color-purple" id="self-paced-modules">
        {/* SELF PACED MODULES */}
        <Container>
          <h2 className="color-white">
            {t('activitiesModulesHeading').toUpperCase()}
          </h2>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row>
              <Column size={9}>
                <p className="color-white activities__self-paced-modules-body mb-1">
                  {t('activitiesModulesBody')}
                </p>
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="bg-color-white rounded-corners pr-1 pl-1 pt-2">
              <Column size={7}>
                <Resource
                  title={exerciseForHealth.title}
                  duration={exerciseForHealth.duration}
                  description={exerciseForHealth.description}
                  img={exerciseForHealth.img}
                  actions={exerciseForHealth.actions}
                  withBorder={false}
                />
              </Column>
              <Column size={5}>
                <Resource
                  title={exerciseForHealthCompanionFamilyGuide.title}
                  description={
                    exerciseForHealthCompanionFamilyGuide.description
                  }
                  img={exerciseForHealthCompanionFamilyGuide.img}
                  actions={exerciseForHealthCompanionFamilyGuide.actions}
                  withBorder={false}
                  alignedButtons={false}
                />
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <div className="pt-3" />
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="bg-color-white rounded-corners pr-1 pl-1 pt-2">
              <Column size={7}>
                <Resource
                  title={nutritiousEating.title}
                  duration={nutritiousEating.duration}
                  description={nutritiousEating.description}
                  img={nutritiousEating.img}
                  actions={nutritiousEating.actions}
                  withBorder={false}
                />
              </Column>
              <Column size={5}>
                <Resource
                  title={nutritiousEatingCompanionFamilyGuide.title}
                  description={nutritiousEatingCompanionFamilyGuide.description}
                  img={nutritiousEatingCompanionFamilyGuide.img}
                  actions={nutritiousEatingCompanionFamilyGuide.actions}
                  withBorder={false}
                  alignedButtons={false}
                />
              </Column>
            </Row>
          </ToggleClassOnVisible>
        </Container>
      </Section>
      <Section>
        {/* EXPLORE THE FACT SHEETS QUIZ AND ACTIVITY */}
        <Container>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <h2>{t('activitiesExploreHeading').toUpperCase()}</h2>
            <Row>
              <Column size={9}>
                <p className="mb-1">{t('activitiesExploreBody')}</p>
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={healthyLivingFactSheets.tophat}
                  title={healthyLivingFactSheets.title}
                  duration={healthyLivingFactSheets.duration}
                  description={healthyLivingFactSheets.description}
                  img={healthyLivingFactSheets.img}
                  actions={healthyLivingFactSheets.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={testYourSkills.tophat}
                  title={testYourSkills.title}
                  duration={testYourSkills.duration}
                  description={testYourSkills.description}
                  img={testYourSkills.img}
                  actions={testYourSkills.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={familyConversationStarters.tophat}
                  title={familyConversationStarters.title}
                  duration={familyConversationStarters.duration}
                  description={familyConversationStarters.description}
                  img={familyConversationStarters.img}
                  actions={familyConversationStarters.actions}
                />
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* QUOTE */}
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Quote
          quote={t('activitiesQuoteBody')}
          author={t('activitiesQuoteAuthor')}
          authorDescription={t('activitiesQuoteAuthorDescription')}
        />
      </ToggleClassOnVisible>
    </Layout>
  );
};

export const query = graphql`
  query FamiliesResources($lang: String, $page: [String]) {
    allResourcesJson(
      filter: { pages: { in: $page }, language: { eq: $lang } }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            moduleName
            download
            noIcon
            asLink
            linkFromImg
            inverse
          }
          horizontal
          className
          language
          pages
        }
      }
    }
  }
`;

export default FamiliesActivities;
