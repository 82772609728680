import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from '../Grid/';
import Image from '../Image';
import Section from '../Section';
import ToggleClassOnVisible from '../ToggleClassOnVisible';
import './styles.scss';

const Quote = ({ img, quote, author, authorDescription, authorLocation }) => {
  const renderImg = () => <Image filename={img} className="quote__image" />;

  const renderCopy = () => (
    <div className="quote__body">
      <div className="h3 text-serif">{quote}</div>
      <div className="quote__attribution">
        <div>{author}</div>
        <div>{authorDescription}</div>
        <div>{authorLocation}</div>
      </div>
    </div>
  );

  return (
    <Section className="quote bg-purple-gradient color-white pt-4 pb-4">
      <ToggleClassOnVisible delay={300} extraClasses="appear-on-visible">
        <Container className="quote__container" fullWidth>
          <div
            className={classNames(
              'quote__content',
              img && 'quote__content--with-img'
            )}
          >
            {img && renderImg()}
            {renderCopy()}
          </div>
        </Container>
      </ToggleClassOnVisible>
    </Section>
  );
};

Quote.defaultProps = {
  img: null,
  quote: null,
  author: null,
  authorDescription: null,
  authorLocation: null,
};

Quote.propTypes = {
  /** The filename for the Image. */
  img: PropTypes.string,
  /** The quote */
  quote: PropTypes.string,
  /** The author of the quote */
  author: PropTypes.string,
  /** The description of the author */
  authorDescription: PropTypes.string,
  /** The location of the author */
  authorLocation: PropTypes.string,
};

export default Quote;
